import { T } from "app/utils/T";
import { LabelText, Button, Switch } from "@classdojo/web/nessie";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
// use Menu component from @classdojo/web, because Menu from nessie
// doesn't position correctly when used in new window
import {
  getAlertDismissalOverrides,
  PopulatedAlertDismissalMetadata,
  removeAlertDismissalOverrides,
  setAlertDismissalOverrides,
  useAlertDismissalMetadataFetcher,
} from "app/pods/alert";
import { useFetchedTeacher } from "app/pods/teacher";
import { IconPencil } from "@web-monorepo/dds-icons";
import { UnstyledButton } from "@classdojo/web";

export const AlertDismissalSectionContainer = () => {
  const { data: dismissals } = useAlertDismissalMetadataFetcher({});
  const dismissalOverrides = getAlertDismissalOverrides();
  const [edit, setEdit] = useState(false);
  const teacher = useFetchedTeacher();

  const [alertOverride, setAlertOverride] = useState(!isEmpty(dismissalOverrides));

  const exampleDismissalShape: PopulatedAlertDismissalMetadata = {
    entityId: teacher._id,
    cardDismissals: { "cardName-classId": ["2021-09-06T16:23:53.587Z"] },
    latestCardDismissal: "2021-09-06T16:23:53.587Z",
  };

  const initialDismissal = dismissals || exampleDismissalShape;

  const [dismissalData, setDismissalData] = useState(
    !isEmpty(dismissalOverrides) ? dismissalOverrides : initialDismissal,
  );

  const setInitialData = () => {
    setAlertDismissalOverrides(initialDismissal);
    setDismissalData(initialDismissal);
  };

  const clearOverrides = () => {
    removeAlertDismissalOverrides();
    setDismissalData(dismissals);
  };

  const toggleAlertOverride = () => {
    const toState = !alertOverride;
    setAlertOverride(toState);

    if (toState) {
      setInitialData();
    } else {
      clearOverrides();
    }
  };

  const updateTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDismissalData(JSON.parse(event.target.value));
  };

  const submit = () => {
    setAlertDismissalOverrides(dismissalData);
    setEdit(false);
  };

  return (
    <div sx={{ width: "100%" }}>
      <p>
        <T
          str="dojo.teacher_web:alert_dismissal_section.to_override_alert"
          fallback="To override alert dismissals, toggle the button below. This will create a copy in localStorage that can be modified and used in place of the database values. Toggling off will delete the localStorage values and fallback to database. For more information see"
        />{" "}
        <LabelText
          level={2}
          sx={{ display: "inline" }}
          href="https://www.notion.so/classdojo/WIP-Alert-Cards-7a78efc6741e40468411ab1cfba80f76"
          target="_blank"
        >
          <T
            str="dojo.teacher_web:alert_dismissal_section.alert_card_documentation"
            fallback="Alert Card Documentation"
          />
        </LabelText>
      </p>
      <div sx={{ display: "flex", alignContent: "center" }}>
        <p sx={{ margin: "dt_s" }}>
          <T str="dojo.teacher_web:alert_dismissal_section.enable_overrides" fallback="Enable overrides?" />
        </p>
        <Switch checked={alertOverride} onCheckedChange={toggleAlertOverride} />
      </div>
      {edit ? (
        <textarea sx={{ width: "100%", height: "75%" }} onChange={updateTextarea}>
          {JSON.stringify(dismissalData, null, 2)}
        </textarea>
      ) : (
        <pre id="code">{JSON.stringify(dismissalData, null, 2)}</pre>
      )}
      {!edit && (
        <UnstyledButton
          data-name="alert_dismissal_section:alert_dismissal_section_container:alert_dismissal_section.edit"
          onClick={() => setEdit(true)}
        >
          <IconPencil size="m" />
        </UnstyledButton>
      )}
      <Button
        data-name="alert_dismissal_section:alert_dismissal_section_container:alert_dismissal_section.submit"
        onClick={submit}
        disabled={!edit}
      >
        <T str="dojo.teacher_web:alert_dismissal_section.submit" fallback="Submit" />
      </Button>
    </div>
  );
};
